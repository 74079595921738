import React from 'react';
import { graphql } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic';

import Layout from '../components/shared/layout';
import SEO from '../components/shared/seo';

import Detail from '../components/beers/detail';

export const BeerTemplate = (props) => <Detail {...props} />;

const Beer = ({ pageContext, data }) => {
	const p = data.prismicBeer.data;
	return (
		<Layout>
			<SEO title={`${p.name.text} ${p.type.text}`} />
			<BeerTemplate
				mast={p.background_image.url}
				singleCan={p.can_glass_image.url}
				multiCan={p.can_detail_image.url}
				tap={p.tap_image.url}
				tagline={p.tagline.html}
				blurb={p.blurb.html}
				packageAvail={p.package_availability.html}
				recipe={p.recipe.html}
				style={p.style.html}
				taste={p.taste.html}
				pairing={p.pairing.html}
				invert={p.inverted}
				next={pageContext.next}
				prev={pageContext.prev}
				buyLink={p.shop_link}
				fullName={`${p.name.text} ${p.type.text}`}
			/>
		</Layout>
	);
};

export default withPreview(Beer);

export const beerQuery = graphql`
	query Beer($id: String!) {
		prismicBeer(id: { eq: $id }) {
			_previewable
			data {
				name {
					text
				}
				type {
					text
				}
				tagline {
					html
				}
				blurb {
					html
				}
				package_availability {
					html
				}
				recipe {
					html
				}
				style {
					html
				}
				taste {
					html
				}
				pairing {
					html
				}
				can_detail_image {
					url
				}
				can_glass_image {
					url
				}
				background_image {
					url
				}
				tap_image {
					url
				}
				inverted
				shop_link
			}
		}
	}
`;
