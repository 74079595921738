import React from 'react';
import { Link } from 'gatsby';

import PreviewCompatibleImage from '../../shared/preview-compatible-image';
import mdToHtml from '../../../util/mdToHtml';

import Button from '../../../components/shared/button';

//import tap from './images/tap.png';
import foodIcon from './images/BeerDetail_Icon_ServeWith.png';
import styleIcon from './images/BeerDetail_Icon_Style.png';
import beerIcon from './images/BeerDetail_Icon_Taste.png';

import StyledSection from './style';

export default ({
	mast,
	singleCan,
	multiCan,
	tap,
	tagline,
	blurb,
	packageAvail,
	recipe,
	style,
	taste,
	pairing,
	invert,
	prev,
	next,
	buyLink,
	fullName,
}) => {
	return (
		<StyledSection mast={mast} invert={invert}>
			<Link to={`/beers/${prev}`}>
				<button className="prev beer-nav">
					PREVIOUS
					<br /> BEER
				</button>
			</Link>
			<Link to={`/beers/${next}`}>
				<button className="next beer-nav">
					NEXT
					<br /> BEER
				</button>
			</Link>
			<div className="pagebg section">
				<div className="masth">
					<div className="container">
						<div className="columns opener">
							<div className="column is-7">
								<div dangerouslySetInnerHTML={{ __html: tagline }} />
							</div>
							<div className="column is-5">
								<img src={singleCan} className="bigcan" />
							</div>
						</div>
					</div>
				</div>
				<div className="container">
					<div className="fridge">
						<h2 className="blurb-head">{fullName}</h2>
						<div
							className="blurb"
							dangerouslySetInnerHTML={{ __html: mdToHtml(blurb) }}
						/>
						<div className="split columns has-border">
							<div className="column hide-mobile">
								<div className="image-container">
									<img src={multiCan} alt="" />
								</div>
							</div>
							<div className="column deets">
								<div>
									<div>
										<h3>PACKAGE AVAILABILITY</h3>
										<div
											dangerouslySetInnerHTML={{
												__html: mdToHtml(packageAvail),
											}}
										/>
									</div>
									<div>
										<h3>INSIDE THE RECIPE</h3>
										<div
											dangerouslySetInnerHTML={{ __html: mdToHtml(recipe) }}
										/>

										{buyLink ? (
											<div className="buy-wrap">
												<Button element="a" link={buyLink} bg="one" external>
													BUY NOW
												</Button>
											</div>
										) : null}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="details-section section">
				<div className="container">
					<div className="columns">
						<div className="column is-2">
							<div className="tap">
								<PreviewCompatibleImage imageInfo={tap} />
								<img src={tap} alt="tap" />
							</div>
						</div>
						<div className="column is-10">
							<div className="constrain">
								<div className="details">
									<div className="detail">
										<div className="icon-wrap">
											<img src={beerIcon} alt="Beer icon" />
										</div>
										<div>
											<h2>TASTE</h2>
											<div dangerouslySetInnerHTML={{ __html: taste }}></div>
										</div>
									</div>
									<div className="detail">
										<div className="icon-wrap">
											<img src={styleIcon} alt="Style icon" />
										</div>
										<div>
											<h2>STYLE</h2>
											<div dangerouslySetInnerHTML={{ __html: style }}></div>
										</div>
									</div>
									<div className="detail">
										<div className="icon-wrap">
											<img src={foodIcon} alt="Food icon" />
										</div>
										<div>
											<h2>SERVE WITH</h2>
											<div dangerouslySetInnerHTML={{ __html: pairing }}></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</StyledSection>
	);
};
